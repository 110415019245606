import { createContextId, useContext } from '@builder.io/qwik';

// TODO(qwik): Refactor ShareRootContext 名前を ConfigContext に変える

export type ShareRootContextValue = Readonly<{
  imagesOrigin: string;
  ftsHostname: string;
  frontHostname: string;
}>;

export const ShareRootContext = createContextId<ShareRootContextValue>('share-root');

export function useShareRootContext(): ShareRootContextValue {
  return useContext(ShareRootContext);
}
